<template>
  <div>
    <div class="page-title">原料入库日志</div>
    <!-- 选项 -->
    <div style="margin-top:1rem;float:left;width:100%;">
      <el-date-picker
        size="mini"
        v-model="form.入库日期"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        >
      </el-date-picker>
      <el-select
        clearable
        filterable
        placeholder="搜索项"
        v-model="form.选项"
        size="mini"
        style="width:120px"
      >
        <el-option
          v-for="item in ['原料id','品名','料号','供应商','规格','合同号','联系人','联系方式','操作人','备注']"
          :value="item"
        >{{ item }}
        </el-option>
      </el-select>
      <el-input
        clearable
        placeholder="请输入关键词"
        size="mini"
        style="width:200px"
        v-model="form.关键词"
        @keydown.native.enter="GetRawInLogs"
      ></el-input>
      <el-button
        size="mini"
        plain
        type="success"
        style="margin-left:3px"
        @click="GetRawInLogs"
      >搜索
      </el-button>
      <el-button
        size="mini"
        type="primary"
        plain
        @click="OutPut"
      >入库日志-导出
      </el-button>
    </div>
    <!-- 表格 -->
    <div style="margin-top:1rem;float:left;width:100%">
      <pl-table
        :data="tableData"
        height="500"
      >
        <pl-table-column
          label="入库日期"
          width="110"
        >
          <template slot-scope="scope">
            <span>{{ FormatDate(scope.row.日期) }}</span>
          </template>
        </pl-table-column>
        <pl-table-column
          v-for="item in ['原料id','合同号','分类','品名','料号']"
          :label="item"
          :prop="item"
          :width="GetTdWidth(item)"
        ></pl-table-column>
        <pl-table-column label="规格" width="150">
          <template slot-scope="scope">
            <span v-html="FormatGG(scope.row.规格)"></span>
          </template>
        </pl-table-column>
        <pl-table-column
          v-for="item in ['单位','入库数量','币种','单价','总金额','付款方式','供应商','联系人','联系方式','操作人']"
          :label="item"
          :prop="item"
          :width="GetTdWidth(item)"
        ></pl-table-column>
        <pl-table-column label="操作时间" width="180">
          <template slot-scope="s">
            <span>{{ FormatTime(s.row.操作时间) }}</span>
          </template>
        </pl-table-column>
        <pl-table-column label="备注" width="150" prop="备注"></pl-table-column>
        <pl-table-column label="修改" width="100">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="修改项=scope.row;修改层=true">修改</el-button>
          </template>
        </pl-table-column>
      </pl-table>
      <el-pagination
        style="margin-top:1rem"
        @current-change="PageChange"
        :current-page="page+1"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
    </div>
    <!-- 入库修改弹窗 -->
    <el-dialog
      title="原辅料入库日志修改"
      :visible.sync="修改层"
      fullscreen
    >
      <el-form>
        <el-form-item label="【分类】" label-width="140px">{{ 修改项.分类 }}</el-form-item>
        <el-form-item label="【品名】" label-width="140px">{{ 修改项.品名 }}</el-form-item>
        <el-form-item label="【单位】" label-width="140px">{{ 修改项.单位 }}</el-form-item>
        <el-form-item label="【规格】" label-width="140px">
          <span v-html="FormatGG(修改项.规格)"></span>
        </el-form-item>
        <el-form-item label="【供应商】" label-width="140px">{{ 修改项.供应商 }}</el-form-item>
        <el-form-item label="【合同号】" label-width="140px">
          <el-input
            v-model="修改项.合同号"
            style="width:300px"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="【联系人】" label-width="140px">
          <el-input
            v-model="修改项.联系人"
            style="width:300px"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="【联系方式】" label-width="140px">
          <el-input
            v-model="修改项.联系方式"
            style="width:300px"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="【入库日期】" label-width="140px">
          <el-date-picker v-model="修改项.日期" size="mini"></el-date-picker>
        </el-form-item>
        <el-form-item label="【入库数量】" label-width="140px">
          <el-input-number size="mini" v-model="修改项.入库数量" :min="1" @change="CalcMoney"></el-input-number>
        </el-form-item>
        <el-form-item label="【币种】" label-width="140px">
          <el-select
            v-model="修改项.币种"
            size="mini"
            clearable
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="item in ['人民币','美元']"
              :value="item"
            >{{ item }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="【单价】" label-width="140px" v-if="修改项.单价||修改项.单价===0">
          <el-input
            v-model="修改项.单价"
            style="width:300px"
            size="mini"
            @input="CalcMoney"
          ></el-input>
        </el-form-item>
        <el-form-item label="【总金额】" label-width="140px" v-if="修改项.总金额||修改项.总金额===0">
          <el-input
            v-model="修改项.总金额"
            style="width:300px"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="【付款方式】" label-width="140px">
          <el-select
            v-model="修改项.付款方式"
            size="mini"
            clearable
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="item in ['月结30天','月结60天','月结90天','现结','票到付款']"
              :value="item"
            >{{ item }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="【备注】" label-width="140px">
          <el-input
            v-model="修改项.备注"
            style="width:500px"
            :rows="3"
            size="mini"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="padding:10px 0 0 140px">
        <el-button @click="修改层 = false">取 消</el-button>
        <el-button type="primary" @click="DoEdit">修改入库日志</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {baseTitle, baseUrl} from '../configs/squareConfig'
import {getRawInLogs, rawInLogEdit} from "../http/api";
import moment from "moment";
import {formRequest} from "../http/formRequest";

export default {
  name: "RawUpLogs",
  data() {
    return {
      form: {},
      tableData: [],
      page: 0,
      pageSize: 100,
      count: 0,
      token: this.$cookies.get('token'),
      修改层: false,
      修改项: {},
    }
  },
  methods: {
    GetRawInLogs() {
      let form = this.form
      form.page = this.page
      getRawInLogs({
        token: this.token,
        form,
      })
        .then(res => {
          this.tableData = res.data.rs
          this.count = res.data.count
          this.pageSize = res.data.pageSize
          this.page = res.data.page
        })
        .catch(err => console.log(err))
    },
    PageChange(e) {
      e = ~~e
      this.page = e - 1
      this.GetRawInLogs()
    },
    OutPut() {
      const url = baseUrl + '/raw-in-logs-output'
      let colScreen = {
        日期: this.form.入库日期,
        选项: this.form.选项,
        关键词: this.form.关键词,
      }
      colScreen = JSON.stringify(colScreen)
      formRequest(url, {
        token: this.token,
        colScreen,
      })
    },
    DoEdit() {
      rawInLogEdit({
        token: this.token,
        form: this.修改项,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.修改项.操作人 = res.data.user
            this.修改层 = false
            this.$message({
              type: "success",
              message: '入库日志修改成功'
            })
          }
        })
        .catch(err => console.log(err))
    },
    CalcMoney() {
      const form = this.修改项
      if (!isNaN(form.入库数量) && !isNaN(form.单价)) {
        this.修改项.总金额 = (parseFloat(form.入库数量) * parseFloat(form.单价)).toFixed(2)
      }
    },
  },
  computed: {
    FormatGG() {
      return gg => {
        if (!gg) return ''
        gg = JSON.parse(gg)
        let arr = []
        for (let key in gg) {
          arr.push(`${key}：${gg[key]}`)
        }
        return arr.join('<br>')
      }
    },
    FormatDate() {
      return date => moment(new Date(date)).format('YYYY/MM/DD')
    },
    FormatTime() {
      return time => moment(new Date(time)).format('YYYY/MM/DD HH:mm:ss')
    },
    GetTdWidth() {
      return item => {
        if (item === '原料id') return 80
        if (item === '合同号') return 140
        if (item === '分类') return 130
        if (item === '品名') return 120
        if (item === '单位') return 80
        if (item === '入库数量') return 100
        if (item === '单价') return 100
        if (item === '供应商') return 120
        if (item === '联系人') return 100
        if (item === '联系方式') return 140
        if (item === '操作人') return 120
      }
    }
  },
  activated() {
    document.title = '原料入库日志 - ' + baseTitle
    this.GetRawInLogs()
  }
}
</script>

<style scoped>

</style>
